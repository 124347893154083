import { loadRemoteEntry } from '@angular-architects/module-federation';
import { remoteEntries } from './app/register.config';

Promise.all(
  remoteEntries.map((entry) =>
    loadRemoteEntry({
      type: 'module',
      remoteEntry: entry.remoteEntry,
    })
  )
)
  .catch((err) => {})
  .then(() => import('./bootstrap'))
  .catch((err) => alert('No se pudo iniciar la aplicación correctamente.'));
