import {
    hostCreditCardsConfig, hostLoansConfig
} from './host.constants.config';
import {
  environment
} from 'src/environments/environment';

// @ts-ignore
const srcCreditCards = (hostCreditCardsConfig === 'CREDIT_CARDS_CONFIG' ? 'http://localhost:4201' : environment.paymentLink + hostCreditCardsConfig) + '/remoteEntry.js';
const srcLoans = (hostLoansConfig === 'LOANS_CONFIG' ? 'http://localhost:4202' : environment.paymentLink + hostLoansConfig) + '/remoteEntry.js';



export const MICRO_FRONTEND = {
  MICRO_CREDIT_CARDS: {
    NAME: 'creditCards',
    SRC: srcCreditCards
  },
  MICRO_LOANS: {
    NAME: 'loans',
    SRC: srcLoans
  },
};
